<template>
  <v-container fluid>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row v-if="!loading">
      <v-col>
        <v-card>
          <v-card-title>
            Пользователь id: {{ $route.params.id }}, nick: {{ user.nick }},
            login: {{ user.login }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  v-show="checkRoles(['general', 'support', 'majorSupport'])"
                  @click="authPlayer(user.id)"
                  >Авторизация</v-btn
                >
              </v-col>
            </v-row>
            <v-divider class="mt-1" />
            <v-row class="mt-2">
              <v-col cols="12" sm="2">
                <v-text-field
                  label="Баланс"
                  :value="(user.balance / 100).toFixed(2)"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  label="Пополнено"
                  :value="(drop[0].drop / 100).toFixed(2)"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  label="Выведено"
                  :value="(handpay[0].handpay / 100).toFixed(2)"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" style="display: flex">
                <v-btn color="red" @click="changePartnerLevel('down')">-</v-btn>
                <v-text-field
                  label="Уровень ПП"
                  :value="user.partnerLevel"
                  readonly
                  outlined
                ></v-text-field>
                <v-btn color="blue" @click="changePartnerLevel('up')">+</v-btn>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  label="Количество рефералов"
                  :value="countReferals"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  label="Количество активных рефералов"
                  :value="countActiveReferals"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  label="Реферальный баланс"
                  :value="(user.balanceByReferals / 100).toFixed(2)"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  label="Получено всего с рефов"
                  :value="(user.totalBalanceByReferals / 100).toFixed(2)"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" v-if="referer">
                <router-link tag="div" :to="`/users/${referer.id}`">
                  <v-text-field
                    label="Реферал"
                    :value="referer.nick"
                    readonly
                    outlined
                  ></v-text-field>
                </router-link>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  label="Дата Регистрации"
                  :value="user.createdAt | moment('YYYY-MM-DD HH:mm:ss')"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  label="Последний визит"
                  :value="user.lastLogin | moment('YYYY-MM-DD HH:mm:ss')"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Настройки</v-card-title>
          <v-card-text>
            <v-row
              class="justify-center"
              v-show="checkRoles(['general', 'majorSupport'])"
            >
              <v-col
                cols="12"
                sm="4"
                class="d-flex justify-center align-center"
              >
                <v-btn color="red" @click="changeBalance('decrement')">-</v-btn>
                <v-text-field
                  class="ml-2 mr-2"
                  v-model="balance"
                  label="Изменение баланса в рублях"
                />
                <v-btn color="blue" @click="changeBalance('increment')"
                  >+</v-btn
                >
              </v-col>
            </v-row>
            <v-divider class="mt-1" />
            <v-row>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="user.blocked"
                  label="Блокировка аккаунта"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="pb-0 pt-0"
                v-show="checkRoles(['general'])"
              >
                <v-switch
                  v-model="user.admin"
                  label="Админ"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="user.is_fake"
                  label="Ютубер"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="user.moderator"
                  label="Модератор"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="user.blockAutoWithdraw"
                  label="Блокировка автовывода"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="user.BlockLveSlots"
                  label="Блокировка Live Slots"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="user.compointExchangeDisable"
                  label="Блокировка обмена компоинтов"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="user.blockWithdraw"
                  label="Блокировка вывода"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="user.usedStarterBonus"
                  label="Стартовый бонус"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="user.isBlockCreatePromo"
                  label="Блокировка создания промо"
                  color="green"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" class="pb-0 pt-0">
                <v-switch
                  v-model="user.chatBanned"
                  label="Блокировка чата"
                  color="green"
                  @click="toggleSwitch"
                  hide-details
                ></v-switch>
                <v-dialog v-model="showModalChatBan" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">Заблокировать чат</span>
                    </v-card-title>
                    <v-card-text>
                      Пользователь: {{ user.nick }}
                      <v-select
                        v-model="banChatTime"
                        label="На время"
                        :items="[
                          { value: 5, name: '5 минут' },
                          { value: 30, name: '30 минту' },
                          { value: 60, name: '60 минут' },
                          { value: 120, name: '120 минут' },
                          { value: 180, name: '180 минут' },
                          { value: 1440, name: '24 часа' },
                          { value: 10080, name: 'Неделя' },
                          { value: 88888888, name: 'Навсегда' }
                        ]"
                        item-text="name"
                        item-value="value"
                      >
                      </v-select>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" @click="setBanChat">Принять</v-btn>
                      <v-btn color="red" @click="closeModalChatBan"
                        >Отмена</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="user.is_fake">
      <v-col>
        <v-card>
          <v-card-title>Доступные провайдеры</v-card-title>
          <v-card-text>
            <v-row class="justify-center">
              <div class="row">
                <div
                  class="col-sm-2 col-5 ml-2"
                  v-for="provider of partnerProviders"
                  :key="provider.id"
                >
                  <v-card-title style="color: black">
                    <v-checkbox
                      v-model="provider.canPlay"
                      @change="changeCanPlayProviders(provider)"
                    ></v-checkbox
                    >{{ provider.name }}</v-card-title
                  >
                </div>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Кнопка для раскрытия/сворачивания блока -->
    <v-btn class="mt-4 mb-4" @click="showGameStats = !showGameStats">
      {{
        showGameStats
          ? "Скрыть статистику по играм"
          : "Показать статистику по играм"
      }}
    </v-btn>

    <!-- Блок статистики по играм -->
    <v-row v-if="!loading && showGameStats">
      <v-col>
        <v-card>
          <v-card-title>Статистика по играм</v-card-title>
          <v-card-text>
            <div class="row">
              <div
                class="col-sm-2 col-5 ml-2"
                v-for="game of gamesLogs"
                :key="game.game_name"
              >
                Игра: {{ game.game_name }}
                <br />
                Сумма ставок:
                {{ (game.bet / 100).toFixed(2) }} P.
                <br />
                Прибыль игрока: {{ (game.revenue / 100).toFixed(2) }} P.
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Дополнительные данные</v-card-title>
          <v-card-text>
            <v-col cols="12" sm="4" class="pb-0 pt-0">
              <v-text-field v-model="searchData" label="Поиск" single-line />
            </v-col>
            <v-tabs v-model="tab">
              <v-tab>Платежи</v-tab>
              <v-tab>Рефералы</v-tab>
              <v-tab>Авторизация</v-tab>
              <v-tab>События баланса</v-tab>
              <v-tab>Использованные промо</v-tab>
              <v-tab>Созданные промо</v-tab>
              <v-tab>Игровые логи</v-tab>
              <v-tab>Детальная история баланса</v-tab>
              <!--<<<<<<< HEAD-->
              <!--=======-->
              <v-tab>История банов чата</v-tab>
              <!--&gt;>>>>>> e53f287585d1efeba482e6547da263b7dc2cd7eb-->
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <Payments
                  :loading="loadingPayments"
                  :data="payments"
                  :search="searchData"
                />
              </v-tab-item>
              <v-tab-item>
                <Referals
                  :loading="loadingReferals"
                  :data="referals"
                  :search="searchData"
                />
              </v-tab-item>
              <v-tab-item>
                <Ips :loading="loadingIps" :data="ips" :search="searchData" />
              </v-tab-item>
              <v-tab-item>
                <History
                  :loading="loadingHistory"
                  :data="history"
                  :search="searchData"
                />
              </v-tab-item>
              <v-tab-item>
                <UsedBonuses
                  :loading="loadingBonuses"
                  :data="bonuses"
                  :search="searchData"
                />
              </v-tab-item>
              <v-tab-item>
                <CreatedBonuses
                  :loading="loadingCreatedBonues"
                  :data="createdBonuses"
                  :search="searchData"
                />
              </v-tab-item>
              <v-tab-item>
                <GameLogs
                  :loading="loadingGameLogs"
                  :data="gameLogs"
                  :search="searchData"
                />
              </v-tab-item>
              <v-tab-item>
                <HistoryDetail
                  :loading="loadingHistoryDetail"
                  :data="historyDetail"
                  :search="searchData"
                />
              </v-tab-item>
              <v-tab-item>
                <ChatBan
                  :loading="LoadingChatBan"
                  :data="chatBan"
                  :search="searchData"
                />
              </v-tab-item>
              <!--              <v-tab-item>-->
              <!--                <HistoryDetail :loading="loadingHistoryDetail" :data="historyDetail" />-->
              <!--              </v-tab-item>-->
            </v-tabs-items>
          </v-card-text>
          <v-btn
            color="primary mb-3 ml-3"
            large
            v-if="tab === 7 && historyDetailShowLoadMore"
            @click="loadMoreHistoryDetail"
          >
            загрузить больше
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import UsersService from "@/services/users.service";
import UserService from "@/services/user.service";
import Payments from "@/views/pages/Users/Payments";
import Referals from "@/views/pages/Users/Referals";
import Ips from "@/views/pages/Users/Ips";
import CreatedBonuses from "@/views/pages/Users/CreatedBonuses";
import UsedBonuses from "@/views/pages/Users/UsedBonuses";
import History from "@/views/pages/Users/History";
// <<<<<<< HEAD
import HistoryDetail from "@/views/pages/Users/HistoryDetail";
// =======
// import HistoryDetail from "@/views/pages/Users/HistoryDetail.vue";
// >>>>>>> e53f287585d1efeba482e6547da263b7dc2cd7eb
import GameLogs from "@/views/pages/Users/GameLogs";
import ChatBan from "@/views/pages/Users/ChatBan.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    Payments,
    Referals,
    Ips,
    History,
    HistoryDetail,
    CreatedBonuses,
    UsedBonuses,
    GameLogs,
    ChatBan
    // HistoryDetail
  },
  data: () => ({
    searchData: "",
    showGameStats: false,
    showModalChatBan: false,
    banChatTime: 5,
    user: {},
    loading: false,
    drop: [],
    countActiveReferals: 0,
    countReferals: 0,
    gamesLogs: [],
    handpay: [],
    referer: {},
    balance: 0,
    tab: 0,
    payments: [],
    loadingPayments: false,
    referals: [],
    loadingReferals: false,
    ips: [],
    loadingIps: false,
    bonuses: [],
    loadingBonuses: false,
    history: [],
    historyDetail: [],
    historyDetailCount: 1000,
    historyDetailShowLoadMore: true,
    loadingHistory: false,
    loadingHistoryDetail: false,
    createdBonuses: [],
    loadingCreatedBonues: false,
    gameLogs: [],
    chatBan: [],
    loadingGameLogs: false,
    // loadingHistoryDetail: false,
    LoadingChatBan: false,
    partnerProviders: []
  }),
  computed: {
    ...mapGetters("user", ["getProfileAdmin"])
  },
  methods: {
    changePartnerLevel(action) {
      switch (action) {
        case "up":
          if (this.user.partnerLevel < 3) {
            this.user.partnerLevel++;
          }
          break;
        case "down":
          if (this.user.partnerLevel > 1) {
            this.user.partnerLevel--;
          }
          break;
      }
    },
    closeModalChatBan() {
      this.user.chatBanned = false;
      this.showModalChatBan = false;
    },
    toggleSwitch() {
      if (this.user.chatBanned) {
        this.showModalChatBan = true;
      } else {
        const currentDate = new Date();
        this.user.chatBannedTime = currentDate.toISOString();
      }
    },
    setBanChat() {
      const originalDateTime = this.user.chatBannedTime;
      const momentObj = moment(originalDateTime);
      const newMomentObj = momentObj.add(this.banChatTime, "minutes");
      this.user.chatBannedTime = newMomentObj.toISOString();
      this.showModalChatBan = false;
      this.LoadingChatBan = true;
      UsersService.createLogBanChat({
        user: this.user.id,
        minutes: this.banChatTime,
        admin: this.getProfileAdmin
      }).then(data => {
        this.chatBan = data;
        console.log(data);
        this.LoadingChatBan = false;
      });
    },
    changeBalance(type) {
      UsersService.changeBalance(Number(this.$route.params.id), {
        amount: this.balance * 100,
        type
      }).then(data => {
        this.user = data;
      });
    },
    changeCanPlayProviders(provider) {
      UsersService.changeCanPlayProviders(Number(this.$route.params.id), {
        providerId: provider.id,
        canPlay: provider.canPlay
      }).then(() => {
        UsersService.getProvidersCanPlay(this.$route.params.id).then(data => {
          this.partnerProviders = data;
        });
      });
    },
    authPlayer(id) {
      // Сначала открываем новое окно
      const newWindow = window.open(); // Это инициирует взаимодействие

      UserService.getPlayerTokenByAdmin({ id })
        .then(token => {
          // После получения токена обновляем URL в ранее открытом окне
          if (newWindow) {
            newWindow.location.href = `https://disi.bet/auth/${token}`;
          }
        })
        .catch(error => {
          // Если что-то пошло не так, закрываем окно
          if (newWindow) {
            newWindow.close();
          }
          console.error("Error fetching player token", error);
        });
    },
    loadMoreHistoryDetail() {
      this.historyDetailCount += 1000;
      this.loadingHistoryDetail = true;
      UsersService.getUserHistoryDetail(
        Number(this.$route.params.id),
        this.historyDetailCount
      ).then(data => {
        if (data.length < this.historyDetailCount) {
          this.historyDetailShowLoadMore = false;
        }
        this.historyDetail = data;
        this.loadingHistoryDetail = false;
      });
    },
    loadUser() {
      this.loading = true;
      UsersService.additional(Number(this.$route.params.id)).then(
        data => {
          this.user = data.user;
          this.drop = data.drop;
          this.countActiveReferals = data.countActiveReferals;
          this.countReferals = data.countReferals;
          this.gamesLogs = data.gamesLogs;
          this.handpay = data.handpay;
          this.referer = data.referer;

          const currentTime = moment();
          const timeVariable = moment(this.user.chatBannedTime);

          if (currentTime.isAfter(timeVariable)) {
            this.user.chatBanned = false;
          }

          this.loadingPayments = true;
          UsersService.getUserPayments(data.user.id).then(data => {
            this.payments = data;
            this.loadingPayments = false;
          });
          this.loadingReferals = true;
          UsersService.getUserReferals(data.user.id).then(data => {
            this.referals = data;
            this.loadingReferals = false;
          });

          this.loadingIps = true;
          UsersService.getUserIps(data.user.id).then(data => {
            this.ips = data;
            this.loadingIps = false;
          });

          this.loadingBonuses = true;
          UsersService.getUserUsedBonuses(data.user.id).then(data => {
            this.bonuses = data;
            this.loadingBonuses = false;
          });

          this.loadingHistory = true;
          UsersService.getUserHistory(data.user.id).then(data => {
            this.history = data;
            this.loadingHistory = false;
          });

          this.loadingHistoryDetail = true;
          UsersService.getUserHistoryDetail(data.user.id).then(data => {
            this.historyDetail = data;
            this.loadingHistoryDetail = false;
          });

          this.loadingCreatedBonues = true;
          UsersService.getUserCreatedBonuses(data.user.id).then(data => {
            this.createdBonuses = data;
            this.loadingCreatedBonues = false;
          });

          this.loadingGameLogs = true;
          UsersService.getUserGameLogs(data.user.id).then(data => {
            this.gameLogs = data;
            this.loadingGameLogs = false;
          });

          this.LoadingChatBan = true;
          UsersService.getUserChatBan(data.user.id).then(data => {
            this.chatBan = data;
            this.LoadingChatBan = false;
          });

          this.loading = false;
        },
        error => {
          console.log(error);
          this.loading = false;
        }
      );
      UsersService.getProvidersCanPlay(this.$route.params.id).then(data => {
        this.partnerProviders = data;
      });
    }
  },
  watch: {
    $route() {
      this.loadUser();
    },
    user: {
      handler(val) {
        UsersService.update({
          data: { ...val },
          where: {
            id: this.user.id
          }
        }).then(
          () => {},
          error => {
            this.$noty.error(error.response.data.message);
            console.log(error);
          }
        );
      },
      deep: true
    }
  },
  created: function() {
    this.loadUser();
  }
};
</script>
<style></style>
