<template>
  <div>
    <v-btn color="primary mt-1 mb-1" large @click="filteredModalShow = true">
      Фильтры
    </v-btn>
    <v-dialog v-model="filteredModalShow" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Фильтры</span>
        </v-card-title>
        <v-card-text>
          id:
          <v-row>
            <v-col>
              <v-text-field
                label="От"
                v-model="filteredData.minID"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="До"
                v-model="filteredData.maxID"
              ></v-text-field>
            </v-col>
          </v-row>
          ExternalId:
          <v-row>
            <v-col>
              <v-text-field
                label="От"
                v-model="filteredData.minExternalId"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="До"
                v-model="filteredData.maxExternalId"
              ></v-text-field>
            </v-col>
          </v-row>
          Напровление:
          <v-select
            v-model="filteredData.codes"
            :items="['Пополнение', 'Вывод']"
            multiple
            chips
          ></v-select>
          Дата:
          <v-row>
            <v-col>
              <v-menu
                v-model="menuMinDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filteredData.minDate"
                    label="от"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filteredData.minDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      filteredData.minDate = null;
                      menuMinDate = false;
                    "
                    >Отмена</v-btn
                  >
                  <v-btn text color="primary" @click="menuMinDate = false"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="menuMaxDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filteredData.maxDate"
                    label="до"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filteredData.maxDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      filteredData.maxDate = null;
                      menuMaxDate = false;
                    "
                    >Отмена</v-btn
                  >
                  <v-btn text color="primary" @click="menuMaxDate = false"
                    >ОК</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          Система:
          <v-select
            v-model="filteredData.systems"
            :items="[
              'Free-Kassa',
              'CoinGate',
              'SkinPay',
              'Yandex Money',
              'Megakassa',
              'Qiwi',
              'GameMoney',
              'Piastrix',
              'SkyPay',
              'P2P'
            ]"
            multiple
            chips
          ></v-select>
          Сумма:
          <v-row>
            <v-col>
              <v-text-field
                label="От"
                v-model="filteredData.minAmount"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="До"
                v-model="filteredData.maxAmount"
              ></v-text-field>
            </v-col>
          </v-row>
          Состояние:
          <v-select
            v-model="filteredData.states"
            :items="[
              'Создана',
              'Выполнена',
              'В процессе',
              'Отменена',
              'Выполнена',
              'Статус',
              'Не созданна на платежном шлюзе',
              'Отменена (адм)'
            ]"
            multiple
            chips
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="acceptFilters">Применить</v-btn>
          <v-btn color="orange" @click="ResetFilters">Сбросить</v-btn>
          <v-btn color="red" @click="closeModalFilters">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="filteredViewData"
      :search="search"
      :loading="loading"
      loading-text="Загрузка... Пожалуйста подождите"
    >
      <template v-slot:item.purseNumber="{ item }">
        <v-edit-dialog
          :return-value.sync="item.purseNumber"
          large
          persistent
          @save="save(item)"
          @cancel="cancel"
        >
          <div>{{ item.purseNumber }}</div>
          <template v-slot:input>
            <div class="mt-4 text-h6">Обновить реквизиты</div>
            <v-text-field
              v-model="item.purseNumber"
              label="Edit"
              single-line
              counter
              autofocus
            />
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import paymentsService from "@/services/payments.service";
import moment from "moment/moment";
export default {
  props: ["data", "loading", "search"],
  name: "Payments",
  watch: {
    data(val) {
      this.viewData = this.formattedData;
      this.filteredViewData = this.viewData;
      console.log(val);
    }
  },
  data: () => ({
    filteredModalShow: false,
    filteredData: {
      minID: null,
      maxID: null,
      minExternalId: null,
      maxExternalId: null,
      minDate: null,
      maxDate: null,
      systems: [],
      minAmount: null,
      maxAmount: null,
      states: [],
      codes: []
    },
    filteredDataConst: {
      minID: null,
      maxID: null,
      minExternalId: null,
      maxExternalId: null,
      minDate: null,
      maxDate: null,
      systems: [],
      minAmount: null,
      maxAmount: null,
      states: [],
      codes: []
    },
    menuMinDate: false,
    menuMaxDate: false,
    filteredViewData: [],
    viewData: [],
    snack: false,
    snackColor: "",
    snackText: "",
    headers: [
      { text: "id", value: "id" },
      { text: "externalID", value: "externalID" },
      { text: "Направление", value: "paymentsCodes.title" },
      { text: "Дата", value: "createdAt" },
      { text: "Система", value: "paymentsSystems.title" },
      { text: "Валюта", value: "payerCurrency" },
      { text: "Курс", value: "course" },
      { text: "Сумма", value: "amount" },
      { text: "Реквизиты", value: "purseNumber" },
      { text: "Тип кошелька", value: "purses.name" },
      { text: "Состояние", value: "paymentsStates.title" }
    ]
  }),
  methods: {
    closeModalFilters() {
      Object.assign(this.filteredData, this.filteredDataConst);
      this.viewData = this.formattedData;
      this.filteredViewData = this.viewData;
      this.filteredModalShow = false;
    },
    ResetFilters() {
      Object.assign(this.filteredData, this.filteredDataConst);
      this.viewData = this.formattedData;
      this.filteredViewData = this.viewData;
    },
    acceptFilters() {
      const filters = [
        item =>
          !this.filteredData.minDate ||
          item.createdAt >= this.filteredData.minDate,
        item =>
          !this.filteredData.maxDate ||
          item.createdAt <= this.filteredData.maxDate,
        item =>
          !this.filteredData.systems.length ||
          this.filteredData.systems.includes(item.paymentsSystems.title),
        item =>
          !this.filteredData.states.length ||
          this.filteredData.states.includes(item.paymentsStates.title),
        item =>
          !this.filteredData.codes.length ||
          this.filteredData.codes.includes(item.paymentsCodes.title),
        item =>
          !this.filteredData.minID || +item.id >= +this.filteredData.minID,
        item =>
          !this.filteredData.maxID || +item.id <= +this.filteredData.maxID,
        item =>
          !this.filteredData.minExternalId ||
          +item.externalID >= +this.filteredData.minExternalId,
        item =>
          !this.filteredData.maxExternalId ||
          +item.externalID <= +this.filteredData.maxExternalId,
        item =>
          !this.filteredData.minAmount ||
          +item.amount >= +this.filteredData.minAmount,
        item =>
          !this.filteredData.maxAmount ||
          +item.amount <= +this.filteredData.maxAmount
      ];

      this.viewData = this.formattedData;
      this.filteredViewData = this.viewData.filter(item =>
        filters.every(filter => filter(item))
      );
      this.filteredModalShow = false;
    },
    save(val) {
      console.log(val);
      paymentsService
        .update({
          data: {
            purseNumber: val.purseNumber
          },
          where: {
            id: val.id
          }
        })
        .then(() => {
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Data saved";
        })
        .catch(() => {
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Canceled";
        });
    },
    cancel(val) {
      console.log(val);
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    }
  },
  computed: {
    formattedData() {
      return this.data.map(item => {
        const formattedDate = moment(item.createdAt).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const amount = (item.amount / item.course / 100).toFixed(2);
        const course = item.course.toFixed(2);
        return {
          ...item,
          createdAt: formattedDate,
          amount: amount,
          course: course
        };
      });
    }
  },
  mounted() {
    this.viewData = this.formattedData;
    this.filteredViewData = this.viewData;
  }
};
</script>

<style scoped></style>
