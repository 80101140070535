<template>
  <v-data-table
    :headers="headers"
    :items="viewData"
    :search="search"
    :loading="loading"
    loading-text="Загрузка... Пожалуйста подождите"
  >
    <template v-slot:item.nick="{ item }">
      <router-link tag="div" :to="`/users/${item.id}`">
        <v-btn color="blue">{{ item.nick }}</v-btn>
      </router-link>
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment/moment";

export default {
  props: ["data", "loading", "search"],
  name: "Referals",
  data: () => ({
    viewData: [],
    headers: [
      { text: "id", value: "id" },
      { text: "Ник", value: "nick" },
      { text: "Зарегистрирован", value: "createdAt" },
      { text: "Внесено Руб.", value: "drop" },
      { text: "Выведено Руб.", value: "handpay" },
      { text: "Отдано рефералу Руб.", value: "totalGivenToReferal" }
    ]
  }),
  computed: {
    formattedData() {
      return this.data.map(item => {
        const createdAt = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
        const drop = (item.drop / 100).toFixed(2);
        const handpay = (item.handpay / 100).toFixed(2);
        const totalGivenToReferal = (item.totalGivenToReferal / 100).toFixed(2);
        return {
          ...item,
          createdAt: createdAt,
          drop: drop,
          handpay: handpay,
          totalGivenToReferal: totalGivenToReferal
        };
      });
    }
  },
  watch: {
    data() {
      this.viewData = this.formattedData;
    }
  },
  mounted() {
    this.viewData = this.formattedData;
  }
};
</script>

<style scoped></style>
