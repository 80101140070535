<template>
  <v-data-table
    :headers="headers"
    :items="viewData"
    :search="search"
    :loading="loading"
    loading-text="Загрузка... Пожалуйста подождите"
  >
  </v-data-table>
</template>

<script>
import moment from "moment/moment";

export default {
  props: ["data", "loading", "search"],
  name: "CreatedBonuses",
  data: () => ({
    viewData: [],
    headers: [
      { text: "id", value: "id" },
      { text: "Код бонуса", value: "code" },
      { text: "Общая сумма", value: "total_amount" },
      { text: "Сумма бонуса", value: "amount" },
      { text: "Лимит", value: "limit" },
      { text: "Активировано", value: "counter" },
      { text: "Дата начала", value: "date_start" },
      { text: "Дата истечения", value: "date_expire" },
      { text: "Дата создания", value: "createdAt" }
    ]
  }),
  computed: {
    formattedData() {
      return this.data.map(item => {
        const date_start = moment(item.date_start).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const date_expire = moment(item.date_expire).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const createdAt = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
        const total_amount = item.total_amount;
        const total_amount_data = (
          (total_amount.amount / 100) *
          total_amount.limit
        ).toFixed(2);
        const amount = (item.amount / 100).toFixed(2);
        return {
          ...item,
          date_start: date_start,
          date_expire: date_expire,
          createdAt: createdAt,
          total_amount_data: total_amount_data,
          amount: amount
        };
      });
    }
  },
  watch: {
    data() {
      this.viewData = this.formattedData;
    }
  },
  mounted() {
    this.viewData = this.formattedData;
  }
};
</script>

<style scoped></style>
