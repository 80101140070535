import { render, staticRenderFns } from "./Referals.vue?vue&type=template&id=13823f01&scoped=true"
import script from "./Referals.vue?vue&type=script&lang=js"
export * from "./Referals.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13823f01",
  null
  
)

export default component.exports