<template>
  <v-data-table
    :headers="headers"
    :items="viewData"
    :search="search"
    :loading="loading"
    loading-text="Загрузка... Пожалуйста подождите"
  >
  </v-data-table>
</template>

<script>
import moment from "moment/moment";

export default {
  props: ["data", "loading", "search"],
  name: "UsedBonuses",
  data: () => ({
    viewData: [],
    headers: [
      { text: "id", value: "id" },
      { text: "Код бонуса", value: "code" },
      { text: "Cумма", value: "bonus_amount" },
      { text: "Дата получения", value: "date_create" }
    ]
  }),
  computed: {
    formattedData() {
      return this.data.map(item => {
        const date_create = moment(item.date_create).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const bonus_amount = (item.bonus_amount / 100).toFixed(2);
        return {
          ...item,
          date_create: date_create,
          bonus_amount: bonus_amount
        };
      });
    }
  },
  watch: {
    data() {
      this.viewData = this.formattedData;
    }
  },
  mounted() {
    this.viewData = this.formattedData;
  }
};
</script>

<style scoped></style>
