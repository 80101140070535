<template>
  <div>
    <v-btn color="primary mt-1 mb-1" large @click="filteredModalShow = true">
      Фильтры
    </v-btn>
    <v-dialog v-model="filteredModalShow" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Фильтры</span>
        </v-card-title>
        <v-card-text>
          id:
          <v-row>
            <v-col>
              <v-text-field
                label="От"
                v-model="filteredData.minID"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="До"
                v-model="filteredData.maxID"
              ></v-text-field>
            </v-col>
          </v-row>
          Событие:
          <v-select
            v-model="filteredData.actions"
            :items="[
              'Обмен компоинтов',
              'Периодический бонус',
              'Увеличение баланса админом',
              'Списание баланса админом',
              'Покупка ника',
              'Списание средств с модератора за Викторину',
              'Начисление выигрыша за викторину игроку',
              'Выигрыш в лотерее',
              'Неизвестный код',
              'Конвертация Реферального баланса в реальный'
            ]"
            multiple
            chips
          ></v-select>
          Сумма:
          <v-row>
            <v-col>
              <v-text-field
                label="От"
                v-model="filteredData.minAmount"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="До"
                v-model="filteredData.maxAmount"
              ></v-text-field>
            </v-col>
          </v-row>
          Баланс после:
          <v-row>
            <v-col>
              <v-text-field
                label="От"
                v-model="filteredData.minBalanceAfter"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="До"
                v-model="filteredData.maxBalanceAfter"
              ></v-text-field>
            </v-col>
          </v-row>
          Баланс до:
          <v-row>
            <v-col>
              <v-text-field
                label="От"
                v-model="filteredData.minBalanceBefore"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="До"
                v-model="filteredData.maxBalanceBefore"
              ></v-text-field>
            </v-col>
          </v-row>
          Дата:
          <v-row>
            <v-col>
              <v-menu
                v-model="menuMinDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filteredData.minDate"
                    label="от"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filteredData.minDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      filteredData.minDate = null;
                      menuMinDate = false;
                    "
                    >Отмена</v-btn
                  >
                  <v-btn text color="primary" @click="menuMinDate = false"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="menuMaxDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filteredData.maxDate"
                    label="до"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filteredData.maxDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      filteredData.maxDate = null;
                      menuMaxDate = false;
                    "
                    >Отмена</v-btn
                  >
                  <v-btn text color="primary" @click="menuMaxDate = false"
                    >ОК</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="acceptFilters">Применить</v-btn>
          <v-btn color="orange" @click="ResetFilters">Сбросить</v-btn>
          <v-btn color="red" @click="closeModalFilters">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="filteredViewData"
      :search="search"
      :loading="loading"
      loading-text="Загрузка... Пожалуйста подождите"
    >
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  props: ["data", "loading", "search"],
  name: "History",
  data: () => ({
    filteredModalShow: false,
    filteredData: {
      minID: null,
      maxID: null,
      minDate: null,
      maxDate: null,
      actions: [],
      minAmount: null,
      maxAmount: null,
      minBalanceBefore: null,
      maxBalanceBefore: null,
      minBalanceAfter: null,
      maxBalanceAfter: null
    },
    filteredDataConst: {
      minID: null,
      maxID: null,
      minDate: null,
      maxDate: null,
      actions: [],
      minAmount: null,
      maxAmount: null,
      minBalanceBefore: null,
      maxBalanceBefore: null,
      minBalanceAfter: null,
      maxBalanceAfter: null
    },
    menuMinDate: false,
    menuMaxDate: false,
    filteredViewData: [],
    viewData: [],
    headers: [
      { text: "id", value: "id" },
      { text: "Дата", value: "createdAt" },
      { text: "Событие", value: "opcodes.description" },
      { text: "Сумма", value: "amount" },
      { text: "Баланс До", value: "balance_before" },
      { text: "Баланс После", value: "balance_after" }
    ]
  }),
  methods: {
    closeModalFilters() {
      Object.assign(this.filteredData, this.filteredDataConst);
      this.viewData = this.formattedData;
      this.filteredViewData = this.viewData;
      this.filteredModalShow = false;
    },
    ResetFilters() {
      Object.assign(this.filteredData, this.filteredDataConst);
      this.viewData = this.formattedData;
      this.filteredViewData = this.viewData;
    },
    acceptFilters() {
      this.viewData = this.formattedData;
      const filters = [
        item =>
          !this.filteredData.minDate ||
          item.createdAt >= this.filteredData.minDate,
        item =>
          !this.filteredData.maxDate ||
          item.createdAt <= this.filteredData.maxDate,
        item =>
          !this.filteredData.actions.length ||
          this.filteredData.actions.includes(item.opcodes.description),
        item =>
          !this.filteredData.minID || +item.id >= +this.filteredData.minID,
        item =>
          !this.filteredData.maxID || +item.id <= +this.filteredData.maxID,
        item =>
          !this.filteredData.minAmount ||
          +item.amount >= +this.filteredData.minAmount,
        item =>
          !this.filteredData.maxAmount ||
          +item.amount <= +this.filteredData.maxAmount,
        item =>
          !this.filteredData.minBalanceBefore ||
          +item.balance_before >= +this.filteredData.minBalanceBefore,
        item =>
          !this.filteredData.maxBalanceBefore ||
          +item.balance_before <= +this.filteredData.maxBalanceBefore,
        item =>
          !this.filteredData.minBalanceAfter ||
          +item.balance_after >= +this.filteredData.minBalanceAfter,
        item =>
          !this.filteredData.maxBalanceAfter ||
          +item.balance_after <= +this.filteredData.maxBalanceAfter
      ];
      this.filteredViewData = this.viewData.filter(item =>
        filters.every(filter => filter(item))
      );
      this.filteredModalShow = false;
    }
  },
  computed: {
    formattedData() {
      return this.data.map(item => {
        const createdAt = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
        const amount = (item.amount / 100).toFixed(2);
        const balance_before = (item.balance_before / 100).toFixed(2);
        const balance_after = (item.balance_after / 100).toFixed(2);
        return {
          ...item,
          createdAt: createdAt,
          amount: amount,
          balance_before: balance_before,
          balance_after: balance_after
        };
      });
    }
  },
  watch: {
    data() {
      this.viewData = this.formattedData;
      this.filteredViewData = this.viewData;
    }
  },
  mounted() {
    this.viewData = this.formattedData;
    this.filteredViewData = this.viewData;
  }
};
</script>

<style scoped></style>
