<template>
  <div>
    <v-btn color="primary mt-1 mb-1" large @click="filteredModalShow = true">
      Фильтры
    </v-btn>
    <v-dialog v-model="filteredModalShow" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Фильтры</span>
        </v-card-title>
        <v-card-text>
          id:
          <v-row>
            <v-col>
              <v-text-field
                label="От"
                v-model="filteredData.minID"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="До"
                v-model="filteredData.maxID"
              ></v-text-field>
            </v-col>
          </v-row>
          Игры:
          <v-select
            v-model="filteredData.GamesNames"
            :items="AllGamesNames"
            multiple
            chips
          ></v-select>
          Сумма изменения:
          <v-row>
            <v-col>
              <v-text-field
                label="От"
                v-model="filteredData.minWinLose"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="До"
                v-model="filteredData.maxWinLose"
              ></v-text-field>
            </v-col>
          </v-row>
          Действия:
          <v-select
            v-model="filteredData.actions"
            :items="['win', 'bet']"
            multiple
            chips
          ></v-select>
          Ставка:
          <v-row>
            <v-col>
              <v-text-field
                label="От"
                v-model="filteredData.minBet"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="До"
                v-model="filteredData.maxBet"
              ></v-text-field>
            </v-col>
          </v-row>
          Баланс после:
          <v-row>
            <v-col>
              <v-text-field
                label="От"
                v-model="filteredData.minBalanceAfter"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="До"
                v-model="filteredData.maxBalanceAfter"
              ></v-text-field>
            </v-col>
          </v-row>
          Баланс до:
          <v-row>
            <v-col>
              <v-text-field
                label="От"
                v-model="filteredData.minBalanceBefore"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="До"
                v-model="filteredData.maxBalanceBefore"
              ></v-text-field>
            </v-col>
          </v-row>
          Откат ставки:
          <v-select
            v-model="filteredData.rollback"
            :items="[true, false]"
            multiple
            chips
          ></v-select>
          Дата:
          <v-row>
            <v-col>
              <v-menu
                v-model="menuMinDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filteredData.minDate"
                    label="от"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filteredData.minDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      filteredData.minDate = null;
                      menuMinDate = false;
                    "
                    >Отмена</v-btn
                  >
                  <v-btn text color="primary" @click="menuMinDate = false"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="menuMaxDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filteredData.maxDate"
                    label="до"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filteredData.maxDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      filteredData.maxDate = null;
                      menuMaxDate = false;
                    "
                    >Отмена</v-btn
                  >
                  <v-btn text color="primary" @click="menuMaxDate = false"
                    >ОК</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="acceptFilters">Применить</v-btn>
          <v-btn color="orange" @click="ResetFilters">Сбросить</v-btn>
          <v-btn color="red" @click="closeModalFilters">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="filteredViewData"
      :search="search"
      :loading="loading"
      :single-expand="false"
      :expanded.sync="expanded"
      show-expand
      loading-text="Загрузка... Пожалуйста подождите"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div style="max-width: 800px; margin: 0 auto">{{ item.data }}</div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  props: ["data", "loading", "search"],
  name: "GameLogs",
  data: () => ({
    filteredModalShow: false,
    filteredData: {
      minID: null,
      maxID: null,
      minDate: null,
      maxDate: null,
      GamesNames: [],
      minWinLose: null,
      maxWinLose: null,
      actions: [],
      minBet: null,
      maxBet: null,
      rollback: [],
      minBalanceBefore: null,
      maxBalanceBefore: null,
      minBalanceAfter: null,
      maxBalanceAfter: null
    },
    filteredDataConst: {
      minID: null,
      maxID: null,
      minDate: null,
      maxDate: null,
      GamesNames: [],
      minWinLose: null,
      maxWinLose: null,
      actions: [],
      minBet: null,
      maxBet: null,
      rollback: [],
      minBalanceBefore: null,
      maxBalanceBefore: null,
      minBalanceAfter: null,
      maxBalanceAfter: null
    },
    menuMinDate: false,
    menuMaxDate: false,
    AllGamesNames: [],
    viewData: [],
    filteredViewData: [],
    expanded: [],
    headers: [
      { text: "id", value: "id" },
      { text: "Дата", value: "createdAt" },
      { text: "Игра", value: "game_id" },
      { text: "Действие", value: "betInfo" },
      { text: "Ставка", value: "bet" },
      { text: "Сумма измения", value: "winLose" },
      { text: "Баланс До", value: "balance_before" },
      { text: "Баланс После", value: "balance_after" },
      { text: "Откат ставки", value: "rollback" },
      { text: "", value: "data-table-expand" }
    ]
  }),
  methods: {
    closeModalFilters() {
      Object.assign(this.filteredData, this.filteredDataConst);
      this.viewData = this.formattedData;
      this.filteredViewData = this.viewData;
      this.filteredModalShow = false;
    },
    ResetFilters() {
      Object.assign(this.filteredData, this.filteredDataConst);
      this.viewData = this.formattedData;
      this.filteredViewData = this.viewData;
    },
    acceptFilters() {
      this.viewData = this.formattedData;
      const filters = [
        item =>
          !this.filteredData.minDate || item.date >= this.filteredData.minDate,
        item =>
          !this.filteredData.maxDate || item.date <= this.filteredData.maxDate,
        item =>
          !this.filteredData.actions.length ||
          this.filteredData.actions.includes(item.betInfo),
        item =>
          !this.filteredData.GamesNames.length ||
          this.filteredData.GamesNames.includes(item.game_id),
        item =>
          !this.filteredData.rollback.length ||
          this.filteredData.rollback.includes(item.rollback),
        item =>
          !this.filteredData.minID || +item.id >= +this.filteredData.minID,
        item =>
          !this.filteredData.maxID || +item.id <= +this.filteredData.maxID,
        item =>
          !this.filteredData.minWinLose ||
          +item.winLose >= +this.filteredData.minWinLose,
        item =>
          !this.filteredData.maxWinLose ||
          +item.winLose <= +this.filteredData.maxWinLose,
        item =>
          !this.filteredData.minBet || +item.bet >= +this.filteredData.minBet,
        item =>
          !this.filteredData.maxBet || +item.bet <= +this.filteredData.maxBet,
        item =>
          !this.filteredData.minBalanceBefore ||
          +item.balance_before >= +this.filteredData.minBalanceBefore,
        item =>
          !this.filteredData.maxBalanceBefore ||
          +item.balance_before <= +this.filteredData.maxBalanceBefore,
        item =>
          !this.filteredData.minBalanceAfter ||
          +item.balance_after >= +this.filteredData.minBalanceAfter,
        item =>
          !this.filteredData.maxBalanceAfter ||
          +item.balance_after <= +this.filteredData.maxBalanceAfter
      ];
      this.filteredViewData = this.viewData.filter(item =>
        filters.every(filter => filter(item))
      );
      this.filteredModalShow = false;
    }
  },
  computed: {
    formattedData() {
      return this.data.map(item => {
        const createdAt = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
        const amount = (item.amount / 100).toFixed(2);
        const bet = (item.bet / 100).toFixed(2);
        const winLose = (item.winLose / 100).toFixed(2);
        const balance_before = (item.balance_before / 100).toFixed(2);
        const balance_after = (item.balance_after / 100).toFixed(2);
        return {
          ...item,
          createdAt: createdAt,
          amount: amount,
          bet: bet,
          winLose: winLose,
          balance_before: balance_before,
          balance_after: balance_after
        };
      });
    }
  },
  watch: {
    data() {
      this.AllGamesNames = [
        ...new Set(
          this.data.reduce((acc, curr) => {
            acc.push(curr.game_id);
            return acc;
          }, [])
        )
      ];
      this.viewData = this.formattedData;
      this.filteredViewData = this.viewData;
    }
  },
  mounted() {
    this.AllGamesNames = [
      ...new Set(
        this.data.reduce((acc, curr) => {
          acc.push(curr.game_id);
          return acc;
        }, [])
      )
    ];
    this.viewData = this.formattedData;
    this.filteredViewData = this.viewData;
  }
};
</script>

<style scoped></style>
