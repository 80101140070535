<template>
  <v-data-table
    :headers="headers"
    :items="viewData"
    :search="search"
    :loading="loading"
    :single-expand="false"
    loading-text="Загрузка... Пожалуйста подождите"
  />
</template>

<script>
import moment from "moment";
export default {
  props: ["data", "loading", "search"],
  name: "ChatBan",
  data: () => ({
    viewData: [],
    expanded: [],
    headers: [
      { text: "id", value: "id" },
      { text: "Дата", value: "dateTime" },
      { text: "Время мин.", value: "banMinutes" },
      { text: "Причина", value: "comment" }
    ]
  }),
  computed: {
    formattedData() {
      return this.data.map(item => {
        const formattedDate = moment(item.dateTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        return { ...item, dateTime: formattedDate };
      });
    }
  },
  watch: {
    data() {
      this.viewData = this.formattedData;
    }
  },
  mounted() {
    this.viewData = this.formattedData;
  }
};
</script>

<style scoped></style>
