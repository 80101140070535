<template>
  <v-data-table
    :headers="headers"
    :items="viewData"
    :search="search"
    :loading="loading"
    loading-text="Загрузка... Пожалуйста подождите"
  >
    <template v-slot:item.cards="{ item }">
      {{ item.cards.login }}
    </template>
    <template v-slot:item.nick="{ item }">
      {{ item.cards.nick }}
    </template>
    <template v-slot:item.handpay="{ item }">
      {{ (item.handpay / 100).toFixed(2) }}
    </template>
    <template v-slot:item.ip="{ item }">
      <router-link :to="`/frod/ip/view/${item.ip}`">
        <v-btn>{{ item.ip }}</v-btn>
      </router-link>
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment/moment";

export default {
  props: ["data", "loading", "search"],
  name: "Ips",
  data: () => ({
    viewData: [],
    headers: [
      { text: "id", value: "id" },
      { text: "Логин", value: "cards.login" },
      { text: "Ник", value: "cards.nick" },
      { text: "IP", value: "ip" },
      { text: "Дата авторизации", value: "date" }
    ]
  }),
  computed: {
    formattedData() {
      return this.data.map(item => {
        const date = moment(item.date).format("YYYY-MM-DD HH:mm:ss");
        return { ...item, date: date };
      });
    }
  },
  watch: {
    data() {
      this.viewData = this.formattedData;
    }
  },
  mounted() {
    this.viewData = this.formattedData;
  }
};
</script>

<style scoped></style>
